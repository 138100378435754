function goToPDP(item, preselectedColor) {
  if (item && (item.url || item._base_url)) {
    try {
      const url = new URL(item.url ?? item._base_url);
      if (preselectedColor) {
        url.searchParams.set('color', preselectedColor);
      } else {
        const selectedColor =
          item.variant &&
          item.variant.find((variant) => {
            if (variant.title == 'Color') return variant;
          });
        if (!selectedColor) return url;
        url.searchParams.set('color', selectedColor.value);
      }

      return url;
    } catch {
      return '/';
    }
  }
  return '/';
}

module.exports = {
  goToPDP,
};
