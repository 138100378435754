<template>
  <div :class="`${className}-brand`">
    <a
      v-if="link"
      itemprop="brand"
      :href="link"
      :class="`${className}-brand--link`">
      {{ brand.name }}
    </a>
    <p
      v-else
      itemprop="brand"
      :class="`${className}-brand--text`">
      {{ brand.name }}
    </p>
    <p
      v-if="productLine"
      class="product-line">
      {{ productLine }}
    </p>
  </div>
</template>

<script>
  import UtilsURL from 'CommonUtils/url';
  import { mapGetter } from 'CommonUtils/store/state.js';

  export default {
    props: {
      brand: {
        type: Object,
        required: true,
      },
      className: {
        type: String,
        required: true,
      },
      showLink: {
        type: Boolean,
        required: false,
        default: true,
      },
      productLine: {
        type: String,
        required: false,
        default: ""
      },
    },
    setup(props) {
      const { brand, showLink } = props;

      const internals = mapGetter('page/getInternals').value;
      const baseUrl = mapGetter('page/getBaseUrl').value;

      const getFullUrlWithQuery = (url) => {
        return UtilsURL.getFullUrlWithQuery({
          baseUrl,
          url,
          query: internals,
        });
      };

      const link = brand.slug && showLink ? getFullUrlWithQuery(`/${brand.slug}/`) : false;

      return {
        link,
      };
    },
  };
</script>
